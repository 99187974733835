import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle as UserIcon } from '@fortawesome/free-solid-svg-icons'

import ActionDropdown from '@legacy/hoodoo/components/ActionDropdown'

import { useAppContext } from '@legacy/voodoo/hooks'
import { useBlockContext } from '@legacy/hoodoo/hooks'


const UserWidget = () => {

  const {nav} = useBlockContext()
  const {userUsername, userDisplayName, logout} = useAppContext()

  const actions = [
    {label: `${userDisplayName} (${userUsername})`, actions: [
      {label: "My Profile", action: () => nav.redirect('profile.home')},
      {label: "Timeclock", action: () => nav.redirect('timeclock.home')},
      {label: "Logout", action: logout},
    ]}
  ]

  return (
    <ActionDropdown actions={actions}>
      <span style={{marginRight: "4px"}}>{userDisplayName}</span>
      <FontAwesomeIcon icon={UserIcon} />
    </ActionDropdown>
  )
}

export default UserWidget
