import { useContext } from 'react'

import { ToasterCTX } from '@legacy/ui/Toaster'

const useToast = () => {
  const toast = useContext(ToasterCTX)

  const danger = (message) => toast.append({color: "danger", message})
  const primary = (message) => toast.append({color: "primary", message})
  const secondary = (message) => toast.append({color: "secondary", message})
  const success = (message) => toast.append({color: "success", message})
  const warning = (message) => toast.append({color: "warning", message})

  return {
    danger,
    primary,
    secondary,
    success,
    warning,
  }

}

export default useToast
