import React from 'react'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown as DropdownIcon } from '@fortawesome/free-solid-svg-icons'

import { maybeFunc } from '@legacy/hoodoo-ng'

import { useBlockContext } from '@legacy/hoodoo/hooks'
import { filterMap } from '@legacy/hoodoo/filters'

import ActionDropdown from '../components/ActionDropdown'
import Checkbox from '../components/Checkbox'
import Flex from '../components/Flex'
import PaginationControls from '../components/PaginationControls'



const useStyles = createUseStyles(theme => ({
  dataTable: {
    width: "100%",
    borderCollapse: "collapse",
    position: "relative",
    '& th': {
      color: theme.colors.primary.backgroundColor,
      backgroundColor: theme.colors.primary.color,
      position: "sticky",
      top: "0px",
    },
  },
  active: {
    backgroundColor: theme.colors.primary.color,
    color: theme.colors.primary.offset,
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.primary.color,
      color: theme.colors.primary.backgroundColor,
    },
  },
  noContentMessage: {
    fontStyle: "oblique",
    textAlign: "center",
  },
  default: {
    border: "none",
    "& tbody": {
      borderCollapse: "collapse",
      borderTop: "1px",
      borderBottom: "1px",
      borderLeft: "1px",
      borderRight: "1px",
      borderStyle: "solid",
      "& td": {
        borderBottomStyle: "solid",
        borderTopStyle: "solid",
        borderTop: "1px",
        borderBottom: "1px",
        borderLeft: "0px",
        borderRight: "0px",
        borderCollapse: "collapse",
        paddingTop: theme.space.xs,
        paddingBottom: theme.space.sm,
        paddingLeft: theme.space.sm,
        paddingRight: theme.space.sm,
      },
    },
  },
}))

/**
 * Cell Display Components
 */
const CellElement = ({Component='td', children, ...componentProps}) => {
  return (
    <Component {...componentProps}>
      {children}
    </Component>
  )
}

const LiteralValue = ({value, ...cellProps}) => (
  <CellElement {...cellProps}>
    {value}
  </CellElement>
)

const Data = ({name, column, rows, rowData}) => {
  const {
    columnValue=(k, d) => d[k],
    cellStyle,
    Cell=LiteralValue,
  } = column

  return (
    <Cell style={cellStyle} value={columnValue(name, rowData)} />
  )
}

const DataRowCheckbox = (props) => {
  const {
    onChange=() => null,
    ...checkboxProps
  } = props

  // special on click to prevent propagation to the data row
  const handleClick = e => {
    e.target.checked = !e.target.checked
    e.stopPropagation()
  }

  const handleOnChange = e => {
    //e.stopPropagation()
    e.target.checked = !e.target.checked
    //e.stopPropagation()
    onChange(e)
  }

  return (
    <Checkbox
      onClick={handleClick}
      onChange={handleOnChange}
    {...checkboxProps}
    />
  )
}

const DataRow = ({columns, context, onSelectClick, isSelected=false, multiSelect=false, rows, rowData}) => {
  const {
    onClick,
    active=() => false,
  } = rows

  const classes = useStyles()

  return (
    <tr
      className={clsx(onClick && classes.clickableRow, active(context, rowData) && classes.active)}
      onClick={onClick ? onClick(context, rowData) : undefined}
    >
      {multiSelect && (
        <td style={{textAlign: "center", width: "1%"}}>
          <DataRowCheckbox
            checked={isSelected}
            onChange={onSelectClick}
          />
        </td>
      )}
      {columns.map(({name, ...column}) => {
        return (
          <Data key={name} context={context} name={name} column={column} rows={rows} rowData={rowData} />
        )
      })}
    </tr>
  )
}

const Headings = (props) => {
  const {
    columns,
    multiSelect=false,
    selectedAll,
    onSelectAll,
    onSelectNone,
  } = props

  const handleSelectAllChange = e => {
    if (e.target.checked) {
      onSelectAll()
    } else {
      onSelectNone()
    }
  }
  return (
    <tr>
      {multiSelect && (
        <th style={{textAlign: "center", width: "1%"}}>
          <DataRowCheckbox
            onChange={handleSelectAllChange}
            checked={selectedAll}
          />
        </th>
      )}
      {columns.map(({name, label, headingStyle={}}) => (
        <th style={headingStyle} key={name}>{label}</th>
      ))}
    </tr>
  )
}

const Filters = (props) => {
  const {
    filters,
    filterValues,
    onFilterChange,
  } = props

  if (isEmpty(filters)) return null

  return (
    <Flex container>
      {Object.entries(filters).map(([filterName, filterDef]) => {
        const {
          Component,
          containerProps={xs: 12, sm: 6, md: 4},
          ...filterProps
        } = filterDef


        const FilterComponent = (typeof Component === 'string')
          ? filterMap[Component] || filterMap.NullFilter
          : Component

        return (
          <Flex
            key={filterName}
            {...containerProps}
          >
            <FilterComponent
              name={filterName}
              onChange={onFilterChange(filterName)}
              value={filterValues[filterName]}
              {...filterProps}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}

const HeadControls = (props) => {
  const {
    columns,
    filters,
    filterValues,
    pagination,
    onFilterChange,
  } = props

  return (
    <tr>
      <td colSpan={columns.length}>
        <Flex container justifyContent="space-between">
          <Flex flexGrow="1">
            <Filters onFilterChange={onFilterChange} filters={filters} filterValues={filterValues} />
          </Flex>
          <Flex>
            <PaginationControls {...pagination} />
          </Flex>
        </Flex>
      </td>
    </tr>
  )
}

const MultiSelectRow = (props) => {
  const {
    actions,
    columns,
    multiSelect=false,
    selected,
  } = props

  // don't show if there is nothing to work on
  if (!multiSelect || selected.length < 1) return null

  // configured actions to use selected ids
  const configuredActions = actions.map(a => {
    a.action = () => a.handler(selected)
    return a
  })

  return (
    <tr>
      <th colSpan={columns.length +1} style={{textAlign: "left", width: "1%"}}>
        <span>{selected.length} selected</span>
        <ActionDropdown
          actions={configuredActions}
        >
          Multi Item Actions <FontAwesomeIcon icon={DropdownIcon} />
        </ActionDropdown>
        </th>
    </tr>
  )
}

const DataTable = (props) => {
  const classes = useStyles()

  const {
    context,
    data=[],
    filterList,
    columns=[],
    tableStyle='default',
    noContentMessage='No rows to display',
    rows={},
  } = props

  const preparedColumns = maybeFunc(columns, context)


  const {
    selected,
    selectedAll,
    multiSelect,
    multiSelectActions,
    select,
    updateFilter,
    filters,
    filterValues,
    pagination
  } = filterList

  const preparedActions = maybeFunc(multiSelectActions, context)

  const handleFilterChange = filterName => value => {
    updateFilter(filterName, value)
  }

  return (
    <table className={clsx(classes.dataTable, classes[tableStyle])}>
      <thead>
        <HeadControls
          pagination={pagination}
          filters={filters}
          filterValues={filterValues}
          onFilterChange={handleFilterChange}
          columns={preparedColumns}
        />
        <MultiSelectRow
          multiSelect={multiSelect}
          columns={preparedColumns}
          selected={selected}
          selectedAll={selectedAll}
          onSelectAll={select.all}
          onSelectNone={select.none}
          actions={Object.values(preparedActions)}
        />
        <Headings
          multiSelect={multiSelect}
          columns={preparedColumns}
          selected={selected}
          selectedAll={selectedAll}
          onSelectAll={select.all}
          onSelectNone={select.none}
        />
      </thead>

      <tbody>
        {data.length ? (
          <React.Fragment>
            {data.map((rowData, id) => {
              return (
                <DataRow
                  key={id}
                  rows={rows}
                  context={context}
                  columns={preparedColumns}
                  rowData={rowData}
                  multiSelect={multiSelect}
                  isSelected={select.isSelected(rowData)}
                  onSelectClick={select.toggle(rowData)}
                />
              )
            })}
          </React.Fragment>
        ) : (
          <tr>
            <td
              className={classes.noContentMessage}
              colSpan={preparedColumns.length}
            >
              {noContentMessage}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const FilterListTableBlock = (props) => {
  const {
    contextKey='filterList',
    ...dataTableProps
  } = props

  const context = useBlockContext()

  const {data, ...filterListContext} = context[contextKey] || {}
  return (
    <DataTable
      context={context}
      data={data}
      filterList={filterListContext}
      {...dataTableProps}
    />
  )
}

export default FilterListTableBlock
