import React from 'react'
import { createUseStyles } from 'react-jss'

import { maybeFunc } from '@legacy/hoodoo-ng'
import { useBlockContext } from '../hooks'
import ActionButton from './ActionButton'

const useStyles = createUseStyles(theme => ({
  actionsWrapper: {
    whiteSpace: "nowrap",
    '& > *': {
      display: "inline-block",
      marginLeft: theme.space.md,
    }
  },
}))

const filterAndSortActions = actions => {
  return Object.entries(actions)
    .filter(([actionName, {enabled=true}]) => enabled)
    .sort(([aName, {sequence:aSeq=100}], [bName, {sequence:bSeq=100}]) => {
      if (aSeq === bSeq) return 0
      return (aSeq > bSeq) ? 1 : -1
    })
}

const ActionsContainer = (props) => {
  const {
    actions={},
    children,
  } = props

  const classes = useStyles()

  const context = useBlockContext()

  if (children) {
    return (
      <div className={classes.actionsWrapper}>
        {children}
      </div>
    )
  }

  return (
    <div className={classes.actionsWrapper}>
      {filterAndSortActions(actions).map(([actionName, action]) => {
        const {
          onClick,
          Component=ActionButton,
          ...actionButtonProps
        } = action

        return (
          <Component
            key={actionName}
            onClick={() => maybeFunc(onClick, context)}
            {...actionButtonProps}
          />
        )
      })}
    </div>
  )
}

export default ActionsContainer
