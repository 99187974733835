import React from 'react'

import { bartholomewCompat as legbe } from '@legacy/apis/legbe'


const authenticator = legbe

// TODO: delete token on failure, continuing to retry cauase the server  to try to verify your token again
// TODO: check auth breaks UI when server is down
export const tokenStorageKey = authenticator.tokenStorageKey

const initAuthState = {
  initialized: false,
  token: localStorage.getItem(tokenStorageKey) || null,
  isAuthenticated: false,
  userId: null,
  username: null,
  displayName: null,
  email: null,
}

const authStateReducer = (state, action) => {
  switch(action.type){
    case 'authSuccess':
      localStorage.setItem(tokenStorageKey, action.token)
      return {...state, isAuthenticated: true, token: action.token}

    case 'authFail':
    case 'logout':
      localStorage.removeItem(tokenStorageKey)
      return {...initAuthState}

    case 'authCheck':
      return {
        ...state,
        initialized: true,
        isAuthenticated: true,
        userId: action.userId || null,
        username: action.username || null,
        displayName: action.displayName || null,
        email: action.email || null,
        roles: action.roles || null,
      }

    default:
      console.log("No such action", action.type)
      return state
  }
}

export const AuthStateCTX = React.createContext({
  ...initAuthState,
  login: () => null,
  logout: () => null,
  check: () => null,
}) 

export const useAuthState = () => {
  return React.useContext(AuthStateCTX)
}

export const AuthStateProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(authStateReducer, initAuthState)

  const {isAuthenticated, initialized} = state

  // TODO: handle bad values when server is not up
  const check = async () => {
    if (!state.token) return false
    let {
      authenticated,
      userId,
      username,
      displayName,
      email,
      roles,
    } = await authenticator.checkAuthentication()
    if (authenticated === true) {
      dispatch({type:"authCheck", authenticated, userId, username, displayName, email, roles})
    }
  }

  const login = async (username, password) => {
    let resp = await authenticator.authenticate(username, password)
    if (resp.token) {
      await dispatch({type:"authSuccess", token:resp.token})
      //check()
    } else {
      dispatch({type:'authFail'})
    }
    return resp
  }

  const logout = async () => {
    await authenticator.logout()
    await dispatch({type: 'logout'})
  }

  const passwordResetRequest = async (username) => {
    return  await authenticator.passwordResetRequest(username)
  }

  const passwordResetComplete = async (token, newPassword, newPasswordConfirm) => {

    return await authenticator.passwordResetComplete(token, newPassword, newPasswordConfirm)
  }

  // eslint-disable-next-line
  const wrappedCheck = React.useCallback(check, [isAuthenticated, initialized])

  React.useEffect(() => {
    wrappedCheck()
  }, [wrappedCheck, isAuthenticated, initialized])

  return (
    <AuthStateCTX.Provider value={{
      ...state,
      login,
      logout,
      check: authenticator.checkAuthentication,
      passwordResetRequest,
      passwordResetComplete,
    }}>
      {children}
    </AuthStateCTX.Provider>
  )
}
