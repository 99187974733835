import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools as ConfigurationIcon } from '@fortawesome/free-solid-svg-icons'
import { faCheck as CheckIcon } from '@fortawesome/free-solid-svg-icons'

import ActionDropdown from '@legacy/hoodoo/components/ActionDropdown'
import ThemeCTX from '@legacy/ui/theme/ThemeCTX'


const UISwitcher = () => {

  const {
    compactness,
    setCompactness,
    setThemeName,
    themeName,
  } = React.useContext(ThemeCTX)

  const checkTheme = (label, forValue) => {
    if (forValue === themeName) {
      return (
        <span>
          {label} <FontAwesomeIcon icon={CheckIcon} />
        </span>
      )
    }
    return (
      <span>{label}</span>
    )
  }

  const checkCompact = (label, forValue) => {
    if (forValue === compactness) {
      return (
        <span>
          {label} <FontAwesomeIcon icon={CheckIcon} />
        </span>
      )
    }
    return (
      <span>{label}</span>
    )
  }

  const actions = [
    {label: 'Theme', actions: [
      {label: checkTheme('Light', 'light'),  action: () => setThemeName('light')},
      {label: checkTheme('Dark', 'dark'),  action: () => setThemeName('dark')},
      {label: checkTheme('None', 'none'),  action: () => setThemeName('none')},
    ]},

    {label: 'Compactness', actions: [
      {label: checkCompact('Extra Tight', 'verySmall'),  action: () => setCompactness('verySmall')},
      {label: checkCompact('Tight', 'small'),  action: () => setCompactness('small')},
      {label: checkCompact('Normal', 'normal'),  action: () => setCompactness('normal')},
      {label: checkCompact('Roomy', 'large'),  action: () => setCompactness('large')},
      {label: checkCompact('Extra Roomy', 'veryLarge'),  action: () => setCompactness('veryLarge')},
    ]},
  ]

  return (
    <ActionDropdown 
      actions={actions}
    >
      <FontAwesomeIcon icon={ConfigurationIcon} />
    </ActionDropdown>
  )
}
export default UISwitcher
