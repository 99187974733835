import React from 'react'
import { merge } from 'lodash'
import { useRouteMatch } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

import apps from '@legacy/apps'

import Witchy from './Witchy'
import useWitchy from './useWitchy'
import withWitchy from './withWitchy'
import WitchyRoute from './Route'
import WitchySwitch from './Switch'
import WitchyContextWrapper from './ContextContainer'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const flattenRoutes = (routes={}, basePath, baseKey='', flattened={}) => {

  for (const [routeName, routeConfig] of Object.entries(routes)) {
    const currentKey = (baseKey.length) ? [baseKey, routeName].join(".") : routeName

    // if virtual, it's just used to structure/group paths
    // if no path, theres probably something wrong, so skip
    if(!routeConfig.virtual && routeConfig.path !== null && routeConfig.path !== undefined) {
      let currentPath = basePath + routeConfig.path
      flattened[currentKey] = {path: currentPath}
    }

    // if we have sub routes, flatten those as well, obviously
    if (routeConfig.routes) {
      flattenRoutes(routeConfig.routes, basePath + routeConfig.path, currentKey, flattened)
    }
  }

  return flattened
}

const flattenedRoutes = merge(
  {},
  ...Object.entries(merge({}, ...apps).apps)
  .map(([appName, app]) => flattenRoutes(app.routes, app.basePath, appName))
)

const witchyRoutes = {
  ...flattenedRoutes,
  'witchy': { path: '/witchy' },
  'witchy.first': { path: '/witchy/first' },
  'witchy.second': { path: '/witchy/second' },
  'witchy.third': { path: '/witchy/thrid' },
  'witchy.list': { path: '/witchy/list' },
  'witchy.single': { path: '/witchy/list/:id' },
  'witchy.singleSub': { path: '/witchy/list/:singleId/sub/:subId' },
}

const useStyles = createUseStyles(theme => ({
  witchyDemo: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  }
}))

const demoData = {
  /**
   * views here could be context aware:
   *  - no context, a product view, basic
   *  - in order context, some basic (shared) details
   *    plus history for this product in this order
   *    (invoices, shipments, etc)
   */
  products: [
    {id:1, name: "Product 1"},
    {id:2, name: "Product 2"},
    {id:3, name: "Product 3"},
  ],
  /**
   * context aware orders:
   *  - no context: just the order read / list
   *  - product context: order than contain this product
   */
  orders: [
    {id: 1, name: "Order 1", products: [1, 2],    contact: 1},
    {id: 2, name: "Order 2", products: [2, 3],    contact: 2},
    {id: 3, name: "Order 3", products: [1, 2, 3], contact: 1},
  ],

  contacts: [
    {id: 1, name: "Contact 1"},
    {id: 2, name: "Contact 2"},
  ]
}

const FindContext = () => {
  const {context} = useWitchy()

  const { url } = useRouteMatch()

  return (
    <div>
      {url} test: {context.test} contacts: {context.contacts?.length}
    </div>
  )
}

const FindContextWrapped = withWitchy((props) => {
  const { context } = props

  const { url } = useRouteMatch()

  return (
    <div>
      [wrapped] {url} test: {context.test} contacts: {context.contacts?.length}
    </div>
  )

})

/**
 * Generic component to provide additional props to child components
 *
 * can be immediate or async
 *
 * with async, optional fallback ui
 *
 * spreads returned context into witchy ctx.context
 */

const SyncDataContextWrapper = (props) => {
  const {
    resource,
    ...extraContext
  } = props

  let data = {[resource]: demoData[resource]}

  return (
    <WitchyContextWrapper {...data} {...extraContext} />
  )
}

/**
 * proof of concept
 *
 * Several things should be handled:
 *  - render at once, or wait till async op is done
 *  - fire async op at once, or do not (provide a func to do it?)
 *  - Loading / Waiting fallback UI
 *  - Error / Async Op Failed fallback UI
 */
const AsyncDataContextWrapper = (props) => {
  const {
    resource,
    //runOnRender,
    //renderAfterRun,
    onRender=true,
    ...extraContext
  } = props

  // provide callback funcs for onOpComplete() and onOpError()?
  //
  // should maybe just receive a function to which it provides
  // params, query string, route info, etc, and the callbacks above

  // rather than expecting a return, it should use the callbacks
  // to determine what next. onComplete can return the context data
  // on error and error object of some kind?
  //
  // how to handle "reload" type funcionatlity? should that just be 
  // part of the returned context? also pass a onOpLoading() func 
  // so the lower level code can hint to the UI it is in the process
  // of refetching the data?
  //

  let [data, setData] = React.useState()
  let [shouldFetch, setShouldFetch] = React.useState(onRender)

  React.useEffect(() => {
    if ( shouldFetch ) {
      ;(async () => {
        setShouldFetch(false)
        await sleep(1000)
        let data = {[resource]: demoData[resource]}
        setData(data)
      })()
    }
  }, [resource, shouldFetch])

  console.log(data)
  return (

    <WitchyContextWrapper
      prepared={data === undefined ? false : true}
      {...data}
      {...extraContext}
    />
  )
}

const Demo = () => {
  const classes = useStyles()

  return (
    <div className={classes.witchyDemo}>
      <Witchy routes={witchyRoutes}>
        <WitchySwitch>
          <WitchyRoute route="witchy" exact={true}>
            <div>default</div>
            <FindContext />
          </WitchyRoute>

          <WitchyRoute route="witchy.first" exact={true}>
            <div>first</div>
            <FindContext />
            <SyncDataContextWrapper resource="contacts">
              <div>first sync data</div>
              <FindContext />
            </SyncDataContextWrapper>
            <SyncDataContextWrapper resource="contacts">
              <div>first sync data with wrapped withWitchy</div>
              <FindContextWrapped />
            </SyncDataContextWrapper>
            <AsyncDataContextWrapper onRender={false} resource="contacts">
              <div>first async data do not fire</div>
              <FindContext />
            </AsyncDataContextWrapper>
            <AsyncDataContextWrapper onRender resource="contacts">
              <div>first async data fire at once</div>
              <FindContext />
            </AsyncDataContextWrapper>
            <AsyncDataContextWrapper onRender renderOnReceive resource="contacts">
              <div>first async data fire at once dont render till</div>
              <FindContext />
            </AsyncDataContextWrapper>
          </WitchyRoute>

          <WitchyRoute route="witchy.second" exact={true}>
            <div>second</div>
            <FindContext />
          </WitchyRoute>

          <WitchyRoute route="witchy.list" exact={true}>
            <div>list</div>
            <FindContext />
          </WitchyRoute>

          <WitchyRoute route="witchy.single" exact={true}>
            <AsyncDataContextWrapper onRender renderOnReceive resource="contacts">
              <div>single</div>
              <FindContext />
            </AsyncDataContextWrapper>
          </WitchyRoute>

          <WitchyRoute route="witchy.singleSub" exact={true}>
            <AsyncDataContextWrapper onRender renderOnReceive resource="contacts">
              <div>single</div>
              <FindContext />
            </AsyncDataContextWrapper>
          </WitchyRoute>
        </WitchySwitch>
      </Witchy>
    </div>
  )
}

export default Demo
