import React from 'react'
import { useForm } from 'react-hook-form'

import Input from '@legacy/hoodoo/components/Input'
import InputLabel from '@legacy/hoodoo/components/InputLabel'
import InputControl from '@legacy/hoodoo/components/InputControl'
import InputErrors from '@legacy/hoodoo/components/InputErrors'


const LoginPasswordResetForm = ({token, onSubmit}) => {

  const {register, handleSubmit, errors, setError} = useForm({
    defaultValues: {username: ""}
  })

  const localOnSubmit = (formData) => {

    if (formData.password.length < 8) {
      setError('password', {type: "manual",
        message: "Password must be at least 8 characters"})
    }

    if (formData.password !== formData.passwordConfirm) {
      setError('passwordConfirm', {type: "manual",
        message: "Passwords must match"})
    }

    onSubmit(formData)

  }

  return (
    <form onSubmit={handleSubmit(localOnSubmit)}>

      <InputControl variant="outlined" hasError={!!errors.password}>
        <InputLabel>Password</InputLabel>
        <Input
          ref={register}
          name="password"
          required
          type="password"
        />
        {!!errors.password && (
          <InputErrors errors={[errors.password.message]} />
        )}
      </InputControl>

      <InputControl variant="outlined" hasError={!!errors.passwordConfirm}>
        <InputLabel>Retype password</InputLabel>
        <Input
          ref={register}
          name="passwordConfirm"
          required
          type="password"
        />
        {!!errors.passwordConfirm && (
          <InputErrors errors={[errors.passwordConfirm.message]} />
        )}
      </InputControl>

      <InputControl variant="outlined">
        <Input
          type="submit"
          value="Change My Password"
        />
      </InputControl>

    </form>
  )
}

export default LoginPasswordResetForm
