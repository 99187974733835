import React from 'react'

import { useAsyncContextReducer } from '@legacy/hoodoo/hooks'
import BlockRenderer from '@legacy/hoodoo/BlockRenderer'
import BlockContext from '@legacy/hoodoo/BlockContext'


/**
 * block to add or alter context asynchronously
 */
const AsyncContextBlock = (props) => {

  const {
    getContext=(ctx) => ({}),
    getDefaultContext=(ctx) => ({}),
    getContextOnRender=true,
    renderOnContext=true,
    contextKey="asyncContext",
    ...passThroughProps
  } = props

  const mounted = React.useRef(false)

  const blockCtx = React.useContext(BlockContext)

  const [contextState, dispatch] = useAsyncContextReducer({
      shouldGetContext: getContextOnRender,
      gettingContext: getContextOnRender,
      context: getDefaultContext(blockCtx)
    })

  const handleGetContext = async () => {
    const newContext = await getContext(blockCtx)

    if (mounted.current) {
      dispatch({action: "receiveContext", context: newContext})
    }
  }

  React.useEffect(() => {
    mounted.current = true
    if (mounted.current && contextState.shouldGetContext) {
      handleGetContext()
    }
    return () => mounted.current = false
  })

  if (renderOnContext && !contextState.hasContext) {
    return <div>Loading...</div>
  }

  const newCtx = {
    ...blockCtx,
    [contextKey]: {
      ...contextState,
      reload: () => dispatch({action: "getContext"})
    }
  }

  return (
    <BlockContext.Provider value={newCtx}>
      <BlockRenderer {...passThroughProps} />
    </BlockContext.Provider>
  )
}

export default AsyncContextBlock
