import React from 'react'

import config from '@legacy/config'

import Button from '@legacy/hoodoo/components/Button'

import { useAppContext } from '../hooks'
import { voodooLog } from '../util'


const VersionCheck = (props) => {

  const { version, build } = useAppContext()

  const intervalRef = React.useRef(null)

  const [hasNewVersion, setHasNewVersion] = React.useState(false)

  const checkClientVersion = async () => {

    let index = await fetch('/', {method: 'GET'})
    let body = await index.text()
    let parsedResponse = (new window.DOMParser()).parseFromString(body, "text/html");
    let foundVersion = parsedResponse.querySelector('meta[name="client-version"]').content.replace('v', '')
    let foundBuild = parsedResponse.querySelector('meta[name="client-build"]').content

    if ( build !== foundBuild ) {
      voodooLog(`New version detected: ${version} -> ${foundVersion} ${build} -> ${foundBuild}`)
      setHasNewVersion(true)
    }

  }

  const toastNewVersion = () => {

    window.toast.warning(
    "A new version of Voodoo has been deployed", {
      timeout: 0,
      actions: (
        <Button
          variant="link"
          size="sm"
          onClick={handleReload}

        >
          Reload Now
        </Button>

      )
    })

  }

  const handleReload = () => {

    window.location.reload()

  }

  React.useEffect(() => {

    const versionCheckInterval = setInterval(
      checkClientVersion, config.VERSION_CHECK_INTERVAL) 

    intervalRef.current = versionCheckInterval

    return () => (intervalRef.current) ? clearInterval(intervalRef.current) : null

  }, []) // eslint-disable-line

  React.useEffect(() => {

    if ( hasNewVersion ) {

      clearInterval(intervalRef.current)
      intervalRef.current = null
      toastNewVersion()

    }

  }, [hasNewVersion]) // eslint-disable-line

  return null

}

export default VersionCheck
