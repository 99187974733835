import React from 'react'
import { useForm } from 'react-hook-form'
import { faEdit as EditIcon } from '@fortawesome/free-solid-svg-icons'
import { faTimes as CloseIcon } from '@fortawesome/free-solid-svg-icons'


import { useChwalEndpoint, call } from '@legacy/chwal'
import ActionButton from '@legacy/hoodoo/components/ActionButton'
import ContentBlock from '@legacy/hoodoo/components/ContentBlock'
import ContentBlockCaption from '@legacy/hoodoo/components/ContentBlockCaption'
import ContentBlockControls from '@legacy/hoodoo/components/ContentBlockControls'
import ContentBlockHead from '@legacy/hoodoo/components/ContentBlockHead'
import ContentBlockFoot from '@legacy/hoodoo/components/ContentBlockFoot'
import ContentBlockTitle from '@legacy/hoodoo/components/ContentBlockTitle'
import ContentBlockContent from '@legacy/hoodoo/components/ContentBlockContent'
import HelpPanel from '@legacy/hoodoo/components/HelpPanel'
import Input from '@legacy/hoodoo/components/Input'
import InputControl from '@legacy/hoodoo/components/InputControl'
import InputLabel from '@legacy/hoodoo/components/InputLabel'
import Textarea from '@legacy/hoodoo/components/Textarea'

import MarkdownRenderer from './MarkdownRenderer'
import HelpContext from './HelpContext'



const Editor = ({post, onClose}) => {

  const {register, handleSubmit} = useForm({defaultValues: post })

  const onSubmit = async (formData) => {
    await call('help.update', formData)
    onClose()
  }
  const doSubmit = handleSubmit(onSubmit)

  return (
    <form style={{width: "100%", height: "100%"}} onSubmit={doSubmit}>
      <ContentBlock card height="100%">

        <ContentBlockHead>
          <ContentBlockTitle>
            <InputControl variant="outlined">
              <InputLabel>Title</InputLabel>
              <Input
                ref={register}
                defaultValue=""
                autoFocus
                placeholder="Title"
                name="title"
              />
            </InputControl>
          </ContentBlockTitle>
        </ContentBlockHead>

        <ContentBlockContent>
          <InputControl variant="outlined">
            <InputLabel>Slug</InputLabel>
            <Input
              ref={register}
              defaultValue=""
              readOnly
              name="slug"
            />
          </InputControl>


          <InputControl variant="outlined">
            <InputLabel>Content</InputLabel>
            <Textarea
              ref={register}
              defaultValue=""
              placeholder="markdown content"
              name="content"
            />
          </InputControl>

        </ContentBlockContent>

        <ContentBlockFoot alignItems="center" justifyContent="space-between">
          <ContentBlockControls>
            <ActionButton
              label="Discard"
              onClick={onClose}
            />
          </ContentBlockControls>
          <ContentBlockControls>
            <ActionButton
              label="Save"
              variant="button"
              color="success"
              onClick={doSubmit}
            />
          </ContentBlockControls>
        </ContentBlockFoot>

      </ContentBlock>
    </form>
  )
}

const Viewer = ({post, onEdit, onExit}) => {
  return (
    <ContentBlock card height="100%">

      <ContentBlockHead>
        <ContentBlockTitle>
          {post.title}
        </ContentBlockTitle>
        <ContentBlockControls>
          <ActionButton icon={CloseIcon} onClick={onExit} />
        </ContentBlockControls>
      </ContentBlockHead>

      <ContentBlockContent border="1px solid lightgray">
        <MarkdownRenderer source={post.content} />
      </ContentBlockContent>

      <ContentBlockFoot justifyContent="space-between" alignItems="center">
        <ContentBlockCaption>
          {post.slug}
        </ContentBlockCaption>
        <ContentBlockControls>
          <ActionButton icon={EditIcon} onClick={onEdit} />
        </ContentBlockControls>
      </ContentBlockFoot>

    </ContentBlock>
      )
}

const HelpBrowser = (props) => {

  const ref = React.useRef()
  const mounted = React.useRef(false)

  const {open, onClose, slug="home"} = React.useContext(HelpContext)

  const [edit, setEdit] = React.useState(false)

  const {data, loading, refetch} = useChwalEndpoint(
  'help.read', {slug}
)

  const onExit = () => {
    onClose()
  }

  const onEdit = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setEdit(true)
  }

  const handleEditClose = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setEdit(false)
    refetch()
  }

  const escAwayHandler = e => {
    if (open && e.key === 'Escape') {
      onClose()
    }
  }

  const clickAwayHandler = e => {
    if (open && ref.current && !ref.current.contains(e.target)) {
      onClose()
    }
  }

  React.useEffect(() => {
    mounted.current = true
    document.addEventListener('click', clickAwayHandler)
    document.addEventListener('keydown', escAwayHandler)
    return () => {
      mounted.current = false
      document.removeEventListener('click', clickAwayHandler)
      document.removeEventListener('keydown', escAwayHandler)
    }
  })

  React.useEffect(() => {
    refetch()
  }, [slug]) // eslint-disable-line

  if (!open) return null

  if (loading) return (<div>Loading...</div>)

  return (
    <HelpPanel
      ref={ref}
      title={data ? data.title : 'Untitled doc'}
    >
      {edit ? (
        <Editor post={data} onClose={handleEditClose} />
      ) : (
        <Viewer onExit={onExit} onEdit={onEdit} post={data} />
      )}
    </HelpPanel>
  )
}

export default HelpBrowser
