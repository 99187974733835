import React from 'react'


const hoodooConfig = {
  apps: {
    devkit: {
      AppComponent: React.lazy(() => import('./App.jsx')),
      basePath: "/devkit",
      label: "Devkit",
      navRoute: 'devkit.home',
      navigation: false,
      navigationSequence: 500,
      routes: {
        home: {
          path: "",
          exact: true,
        },
      },
    },
  },
}

export default hoodooConfig
