import config from '@legacy/config'

export const tokenStorageKey = 'legebe-jwt'

const getWebTokenHeaderContent = () => {
  let token = localStorage.getItem(tokenStorageKey)
  if (token) {
    return `Bearer ${token}`
  }
  return ''
}

const baseUrl = `${config.API_PROTOCOL}://${config.API_HOST}${(config.API_PORT)?":"+config.API_PORT:''}${config.API_BASEPATH}`


// NEW LEGBE V1 FUNCTIONS HERE

export const callV1 = async ({path, method:callMethod, body:payload}) => {
  let url, body, response, authHeaderContent, method, responseContent

  try {

    url = baseUrl + '/legbe/v1' + path

  } catch ( e ) {

    // TODO: handle bad url concatenation

  }

  try {

    method = callMethod.toUpperCase()

  } catch ( e ) {

    // TODO: handle bad method?

  }

  try {

    authHeaderContent = getWebTokenHeaderContent()
  } catch ( e ) {

    // TODO: handle failtuer in token setup

  }
  

  try {

    body = (payload) ? JSON.stringify(payload) : null

  } catch ( e ) {

    // TODO: handle body encode errors
  }

  try {
    response = await fetch(url, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeaderContent,
      },
    })

  } catch ( e ) {

    // TODO: handle fetch errors

  }

  if (!response || !response.ok) {

    // TODO: handle bad html responses

  }

  try {

    responseContent = await response.json()

  } catch (e) {

    // TODO: handle response decode errors

  }

  if ( !responseContent || (responseContent.status && responseContent.status !== 'ok') ) {
    
    // TODO: handle bad api response status

  }

  return responseContent

}

const status = async () => {

  return await callV1({
    path: '/status', method: 'GET'
  })

}

const check = async () => {

  return await callV1({
    path: '/check', method: 'POST'
  })

}

const auth = async ({username, password}) => {

  return await callV1({
    path: '/auth', method: 'POST',
    body: { username, password },
  })

}

const deauth = async () => {

  return await callV1({
    path: '/deauth', method: 'POST'
  })

}

const resetRequest = async ({username}) => {

  return await callV1({
    path: '/reset-request', method: 'POST',
    body: { username },
  })

}

const resetComplete = async ({token, newPassword, newPasswordConfirm}) => {

  return await callV1({
    path: '/reset-complete', method: 'POST',
    body: { token, newPassword, newPasswordConfirm },
  })

}

const bartyCompatAuthenticate = async (username, password) => {

  let response = await auth({username, password})

  return {
    token: response.data.token,
  }
}

const bartyCompatCheckAuthentication = async () => {

  let response =  await check()


  if ( !response ) {
    return {
      authenticated: null,
    }
  }

  let { data: { authenticated, tokenExpires, authUser } } = response

  let compatResponse = {
    authenticated,
    tokenExpires,
    ...authUser,
    userId: authUser.id,
  }

  return compatResponse

}

const bartyCompatLogout = () => {

  return deauth()

}

const bartyCompatPasswordResetRequest = (username) => {

  return resetRequest({username})

}

const bartyCompatPasswordResetComplete = (token, newPassword, newPasswordConfirm) => {

  return resetComplete({token, newPassword, newPasswordConfirm})

}

export const v1 = {
    status,
    check,
    auth,
    deauth,
    resetRequest,
    resetComplete,
}

export const bartholomewCompat = {
  authenticate: bartyCompatAuthenticate,
  checkAuthentication: bartyCompatCheckAuthentication,
  logout: bartyCompatLogout,
  passwordResetRequest: bartyCompatPasswordResetRequest,
  passwordResetComplete: bartyCompatPasswordResetComplete,
  tokenStorageKey,
}

const defExports = {
  v1,
  bartholomewCompat,
}

export default defExports
