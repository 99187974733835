import { useForm as useReactHookForm } from 'react-hook-form'


const useForm = (...args) => {
  const form = useReactHookForm(args)


  const setApiValidationErrors = (errorResponse={}) => {
    const {validationErrors={}} = errorResponse

    if (validationErrors === null || validationErrors === undefined) {
      return
    }

    // FIXME: does not handle array object validation errors
    Object.entries(validationErrors).forEach(([fieldName, messages]) => {
      form.setError(fieldName, {
        type: "manual",
        message: messages,
      })
    })
  }

  const inputErrorProps = (inputName) => {
    return {
      hasError: form.errors && !!form.errors[inputName],
      errors: form.errors && form.errors[inputName]?.message,
    }
  }

  const onSubmit = (callback) => () => {
    form.clearErrors()
    form.handleSubmit(callback)()
  }

  form.setApiValidationErrors = setApiValidationErrors
  form.inputErrorProps = inputErrorProps
  form.onSubmit = onSubmit

  return form
}

export default useForm
