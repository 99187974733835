import React from 'react'
import { merge } from 'lodash'
import { Switch, Route } from "react-router-dom"
import Witchy from '@legacy/witchy/Witchy'
import WitchyDemo from '@legacy/witchy/Demo'
import HoodooDemo from '@legacy/hoodoo/demo'

import GlobalContexts from './GlobalContexts'
import GlobalComponents from './GlobalComponents'

import hoodooApps from '@legacy/apps'

import BlockRenderer from '@legacy/hoodoo/BlockRenderer'
import CompanyBrand from '@legacy/resources/CompanyLogo'

import { useAppContext } from './hooks'
import Fallback from './Fallback'

import {
  TopNavBarLayoutBlock,
  BaseAppRouterBlock,
  BaseContextBlock,
} from '@legacy/hoodoo/blocks'


const witchyRoutes = (() => {
  const flattenRoutes = (routes={}, basePath, baseKey='', flattened={}) => {

    for (const [routeName, routeConfig] of Object.entries(routes)) {
      const currentKey = (baseKey.length) ? [baseKey, routeName].join(".") : routeName

      // if virtual, it's just used to structure/group paths
      // if no path, theres probably something wrong, so skip
      if(!routeConfig.virtual && routeConfig.path !== null && routeConfig.path !== undefined) {
        let currentPath = basePath + routeConfig.path
        flattened[currentKey] = {path: currentPath}
      }

      // if we have sub routes, flatten those as well, obviously
      if (routeConfig.routes) {
        flattenRoutes(routeConfig.routes, basePath + routeConfig.path, currentKey, flattened)
      }
    }

    return flattened
  }

  const flattenedRoutes = merge(
    {},
    ...Object.entries(merge({}, ...hoodooApps).apps)
    .map(([appName, app]) => flattenRoutes(app.routes, app.basePath, appName))
  )

  return {
    ...flattenedRoutes,
  }

})()

const AppController = ({children}) => {

  const appContext = useAppContext()

  if (appContext.isAppLoaded !== true) {
    return (
      <Fallback Brand={CompanyBrand} />
    )
  }

  return children

}

const TheApp = () => {
  const appContext = useAppContext()

  const hoodooConfig = {

    // The base application 
    Component: BaseContextBlock,
    appConfigs: hoodooApps,
    extraContext: {appContext},
    content: [

      { // The APP Layout
        Component: TopNavBarLayoutBlock,
        Brand: CompanyBrand,
        brandString: "Voodoo",
        content: [

          { // The App Router
            Component: BaseAppRouterBlock,
          }
        ],
      },

    ],
  }

  return (
    <BlockRenderer {...hoodooConfig} />
  )

}

const renderWitchyDemo = process.env.NODE_ENV === 'development'
const renderHoodooDemo = process.env.NODE_ENV === 'development'

const Voodoo = () => {

  return (
    <GlobalContexts>
      <AppController>
        <Witchy routes={witchyRoutes}>
          <Switch>
            {renderWitchyDemo && (
              <Route path="/witchy" exact={false}>
                <WitchyDemo />
              </Route>
            )}
            {renderHoodooDemo && (
              <Route path="/hoodoo" exact={false}>
                <HoodooDemo />
              </Route>
            )}
            <Route path="/" exact={false}>
              <TheApp />
            </Route>
          </Switch>
        </Witchy>
      </AppController>
      <GlobalComponents />
    </GlobalContexts>
  )
}

export default Voodoo
