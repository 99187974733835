import React from 'react'
import { useForm } from 'react-hook-form'

import Input from '@legacy/hoodoo/components/Input'
import InputLabel from '@legacy/hoodoo/components/InputLabel'
import InputControl from '@legacy/hoodoo/components/InputControl'
import InputErrors from '@legacy/hoodoo/components/InputErrors'


const LoginResetRequestForm = ({onSubmit}) => {

  const {register, handleSubmit, errors} = useForm({
    defaultValues: {username: ""}
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <InputControl variant="outlined" hasError={!!errors.username}>
        <InputLabel>Username</InputLabel>
        <Input
          ref={register}
          autoFocus
          name="username"
          type="text"
          required
        />
        {!!errors.username && (
          <InputErrors errors={errors.username} />
        )}
      </InputControl>

      <InputControl variant="outlined">
        <Input
          type="submit"
          value="Reset My Password"
        />
      </InputControl>

    </form>
  )
}

export default LoginResetRequestForm
