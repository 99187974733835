import React from 'react'
import { createUseStyles } from 'react-jss'

import { useBlockContext } from '@legacy/hoodoo/hooks'

const useStyles = createUseStyles(theme => ({
  linkContainer: {
    display: "inline-block",
    cursor: "pointer",
  }
}))

const NavLink = ({to, children}) => {
  const classes = useStyles()
  const {nav:{redirect}} = useBlockContext()

  return (
    <div
      className={classes.linkContainer}
      onClick={() => redirect(to)}
    >
      {children}
    </div>
  )
}

export default NavLink
