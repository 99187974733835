import React from 'react'


const config = {
  apps: {
    sales: {
      AppComponent: React.lazy(() => import('./App.jsx')),
      basePath: "/sales",
      navRoute: "sales.home",
      navigation: true,
      navigationSequence: 200,
      label: "Sales",
      routes: {
        home: {
          path: "",
          exact: true,
        },
        orders: {
          path: "/orders",
          isVirtual: true,
          routes: {
            list: { path: "", exact: true },
            create: { path: "/new", exact: true },
            update: { path: "/:orderId/edit", exact: true },
            read: { path: "/:orderId", exact: true },
          },
        },
        customers: {
          path: "/customers",
          isVirtual: true,
          routes: {
            list: { path: "", exact: true },
            create: { path: "/new", exact: true },
            update: { path: "/:contactId/edit", exact: true },
            read: { path: "/:contactId", exact: true },
          },
        },
        openLines: {
          path: "/lines",
          isVirtual: true,
          routes: {
            list: { path: "", exact: true },
          },
        },
      },
    },
  },
}

export default config
