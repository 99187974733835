import React from 'react'
import { BrowserRouter } from "react-router-dom"

import { AuthStateProvider } from '@legacy/authentication'
import VoodooThemeProvider from '@legacy/ui/theme/ThemeProvider'
import { ToastProvider } from '@legacy/hoodoo/components/Toaster'
import HelpContextProvider from './components/HelpContextProvider'
import AppContextProvider from './components/AppContextProvider'
import AuthCheck from './components/AuthCheck'
import ServerCheck from './components/ServerCheck'
import VersionCheck from './components/VersionCheck'


const GlobalContexts = ({children}) => {

  return (
    <AppContextProvider>
      <BrowserRouter>
        <VoodooThemeProvider>

          <AuthStateProvider>

            <ServerCheck />

            <AuthCheck />

            <VersionCheck />

            <HelpContextProvider>

              <ToastProvider>

                {children}

              </ToastProvider>

            </HelpContextProvider>

          </AuthStateProvider>

        </VoodooThemeProvider>
      </BrowserRouter>
    </AppContextProvider>
  )
}

export default GlobalContexts
