import React from 'react'
import { isEmpty } from 'lodash'

import ActionsContainer from '@legacy/hoodoo/components/ActionsContainer'
import ContentBlock from '@legacy/hoodoo/components/ContentBlock'
import ContentBlockCaption from '@legacy/hoodoo/components/ContentBlockCaption'
import ContentBlockTitle from '@legacy/hoodoo/components/ContentBlockTitle'
import ContentBlockHead from '@legacy/hoodoo/components/ContentBlockHead'
import ContentBlockContent from '@legacy/hoodoo/components/ContentBlockContent'
import ContentBlockControls from '@legacy/hoodoo/components/ContentBlockControls'
import Flex from '@legacy/hoodoo/components/Flex'


const Card = (props) => {
  const {
    label,
    labelSize='lg',
    caption,
    actions,
    children,
    containerProps={},
    contentContainerProps={},
    card=true,
    asContainer=false,
    noMargins=false,
  } = props

  return (
    <ContentBlock
      card={card}
      transparent
      noMargins={noMargins}
      //overflow="hidden"
      {...containerProps}>
      <ContentBlockHead>

        {(label || caption) && (
          <ContentBlockTitle size={labelSize}>
            {label}
            {(caption) && (
              <ContentBlockCaption>
                {caption}
              </ContentBlockCaption>
            )}
          </ContentBlockTitle>
        )}

        {!isEmpty(actions) && (
          <ContentBlockControls>
            <ActionsContainer actions={actions} />
          </ContentBlockControls>
        )}

      </ContentBlockHead>

      {(asContainer) ? (

        <Flex container
          //overflowY="auto"
          {...contentContainerProps}
        >
          {children}
        </Flex>
      ) : (
        <ContentBlockContent
          //overflowY="auto"
          {...contentContainerProps}
        >
          {children}
        </ContentBlockContent>
      )}


    </ContentBlock>
  )
}

export default Card
