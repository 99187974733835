import React from 'react'

import config from '@legacy/config'
import { useAuthState } from '@legacy/authentication'

import { msTextualRelativeTime } from '@legacy/hoodoo/util'

import { useAppContext } from '../hooks'
import { voodooLog } from '../util'


const AuthCheck = (props) => {

  const intervalRef = React.useRef(null)

  const { logout, isApiAuthenticated } = useAppContext()

  const { check } = useAuthState()

  const [showWarning, setShowWarning] = React.useState(false)

  const checkAuth = async () => {

    let response = await check()

    if ( response?.authenticated === null ) {

      // do nothing if the call fails, no need to deauth on server down
      voodooLog("AuthCheck: NOP: check request failed")
      return

    }

    let { authenticated=false, tokenExpires=null } = response

    if ( isApiAuthenticated === true && authenticated !== true ) {

      window.toast.danger("Session expired. Login again")
      logout()

    }

    if ( tokenExpires ) {

      try {

        let date = new Date(tokenExpires)

        let expiresIn = date.getTime() - Date.now()

        if ( showWarning === false && expiresIn < config.AUTH_CHECK_WARN ) { 

          voodooLog('Session expires in', msTextualRelativeTime(expiresIn))

          setShowWarning(true)

        }

      } catch {

        // do nothing

      }

    }

  }

  React.useEffect(() => {

    if (showWarning === true && isApiAuthenticated === true) {

      window.toast.danger(
        "Your session is expiring", {timeout: config.AUTH_CHECK_WARN})

    }

  }, [showWarning, isApiAuthenticated])

  React.useEffect(() => {

    if ( isApiAuthenticated ) {

      intervalRef.current = setInterval(
        checkAuth,
        config.AUTH_CHECK_INTERVAL)

    } else {

      // if auth is set false somewhere else, stop checking
      if (intervalRef.current) clearInterval(intervalRef.current)

    }

    // stop checking when unloaded
    return () => (intervalRef.current)
      ? clearInterval(intervalRef.current)
      : null

  }, [isApiAuthenticated]) // eslint-disable-line

  return null

}

export default AuthCheck
