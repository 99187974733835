import React from 'react'
import { isEmpty } from 'lodash'

import BlockRenderer from '@legacy/hoodoo/BlockRenderer'
import { useBlockContext } from '@legacy/hoodoo/hooks'
import { maybeFunc } from '@legacy/hoodoo-ng'

import Flex from '../components/Flex'
import ActionsContainer from '../components/ActionsContainer'
import ContentBlock from '../components/ContentBlock'
import ContentBlockHead from '../components/ContentBlockHead'
import ContentBlockContent from '../components/ContentBlockContent'
import ContentBlockTitle from '../components/ContentBlockTitle'
import ContentBlockCaption from '../components/ContentBlockCaption'
import ContentBlockControls from '../components/ContentBlockControls'


const CardBlock = (props) => {
  const {
    actions:actionDefs={},
    asContainer=false, // do not render content inside ContentBlockContent
    caption,
    card=false,
    containerProps={},
    contentContainerProps={},
    label,
    labelSize='lg',
    Content,
    ...passThroughProps
  } = props

  const context = useBlockContext()

  const actions = maybeFunc(actionDefs, context) 

  /**
   * continue rendering blocks
   */
  return (
    <Flex xs={12} {...containerProps}>
      <ContentBlock card={card} flexGrow="1">
        <ContentBlockHead>

          {(label || caption) && (
            <ContentBlockTitle size={labelSize}>
              {maybeFunc(label, context)}
              {(caption) && (
                <ContentBlockCaption>
                  {maybeFunc(caption, context)}
                </ContentBlockCaption>
              )}
            </ContentBlockTitle>
          )}

          {!isEmpty(actions) && (
            <ContentBlockControls>
              <ActionsContainer actions={actions} />
            </ContentBlockControls>
          )}

        </ContentBlockHead>

        {Content ? (

          (asContainer) ? (
            <Content {...passThroughProps} />
          ) : (
            <ContentBlockContent>
              <Content {...passThroughProps} />
            </ContentBlockContent>
          )

        ) : (

          (asContainer) ? (
            <Flex container {...contentContainerProps}>
              {passThroughProps.content.map((blockDef, key) => {
                return (
                  <BlockRenderer key={key} {...blockDef} />
                )
              })}
            </Flex>
          ) : (
            <ContentBlockContent>
              {passThroughProps.content.map((blockDef, key) => {
                return (
                  <BlockRenderer key={key} {...blockDef} />
                )
              })}
            </ContentBlockContent>
          )

        )}

      </ContentBlock>
    </Flex>
  )
}

export default CardBlock
