import React from 'react'
import { createUseStyles } from 'react-jss'

import BaseNav from '../components/BaseNav'
import UISwitcher from '../components/special/UISwitcher'
import BlockRenderer from '@legacy/hoodoo/BlockRenderer'
import UserWidget from '@legacy/voodoo/components/UserWidget'
import { useAppContext } from '@legacy/voodoo/hooks'


const useStyles = createUseStyles(theme => ({
  topAppWindow: {
    display: "flex",
    flexDirection: 'column',
    flexWrap: "no-wrap",
    width: "100vw",
    height: "100vh",
  },
  topAppBackground: {
    position: "absolute",
    height: "100vh",
    width:"100vw",
    textAlign: "center",
    fill: theme.colors.primary.color,
    zIndex: -1,
    '& *': {
      maxHeight: "100%",
      maxWidth: "100%",
    }
  },
  brand: {
    opacity: .25,
    fill: theme.colors.primary.color,
  },
  topAppHead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: theme.size.lg,
    padding: theme.space.md,
    backgroundColor: theme.colors.primary.color,
    color: theme.colors.primary.backgroundColor,
  },
  topAppHeadBrand: {
    fontSize: theme.size.xl,
  },
  topAppHeadLeft: {
    marginRight: theme.space.md,
    marginLeft: theme.space.md,
    flexGrow: 1,
    '& > *': {
      boxSizing: "border-box",
      paddingLeft: theme.space.lg,
    }
  },
  topAppHeadRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    '& > *': {
      marginLeft: theme.space.md,
    },
  },
  topAppBody: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },
  topAppFoot: {
    textAlign: "center",
    fontSize: theme.size.md,
    fontStyle: "oblique",
    opacity: .5,
  },
}))

const TopNavBarLayoutBlock = (props) => {

  const {
    Brand,
    brandString="No Name here",
    ...passThroughProps
  } = props

  const classes = useStyles()

  const appContext = useAppContext()


  return (
    <div className={classes.topAppWindow}>
      <div className={classes.topAppBackground}>
        <Brand className={classes.brand} />
      </div>

      <div className={classes.topAppHead}>
        <div className={classes.topAppHeadBrand}>
          {brandString}
        </div>
        <div className={classes.topAppHeadLeft}>
          <BaseNav />
        </div>
        <div className={classes.topAppHeadRight}>
          <UserWidget />
          <UISwitcher />
        </div>
      </div>

      <div className={classes.topAppBody}>
        <BlockRenderer {...passThroughProps} />
      </div>

      <div className={classes.topAppFoot}>
        Voodoo {appContext.version} {appContext.build} © {new Date().getFullYear()} Holmes Hobbies LLC / Jonathan Anderson
      </div>
    </div>
  )
}

export default TopNavBarLayoutBlock
