import React from 'react'


const config = {
  apps: {
    timeclock: {
      AppComponent: React.lazy(() => import('./TimeclockApp.jsx')),
      basePath: "/timeclock",
      navRoute: "timeclock.home",
      navigation: false,
      navigationSequence: 800,
      label: "Timeclock",
      routes: {
        home: {
          path: "/",
          exact: true,
        },
      },
    },
    payroll: {
      AppComponent: React.lazy(() => import('./PayrollApp.jsx')),
      basePath: "/payroll",
      requireRole: "manager",
      navRoute: "payroll.history",
      navigation: true,
      navigationSequence: 200,
      label: "Payroll",
      routes: {
        home: {
          path: "/",
          exact: true,
        },
        current: {
          path: "/current",
          exact: true,
        },
        history: {
          path: "/history",
          exact: true,
        },
        config: {
          path: "/config",
          exact: true,
        },
      },
    },
  },
}

export default config
