import config from '@legacy/config'
import { replace } from 'lodash'

export const tokenStorageKey = 'voodoo-jwt'
const getWebTokenHeaderContent = () => {
  let token = localStorage.getItem(tokenStorageKey)
  if (token) {
    return `Bearer ${token}`
  }
  return ''
}

const baseUrl = `${config.API_PROTOCOL}://${config.API_HOST}${(config.API_PORT)?":"+config.API_PORT:''}${config.API_BASEPATH}`

const _call = async (method, path, payload, fetchOpts={}) => {
  let url = `${baseUrl}${path}`
  try {
    let resp = await fetch(url, {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getWebTokenHeaderContent(),
      },
      body: (payload) ? JSON.stringify(payload) : null,
      ...fetchOpts,
    })

    if (!resp.ok) {} // ignore bad request codes

    let data = await resp.json()

    return data

  } catch (e) {

    console.log("API Fetch Error:", e)

  }
}

export const requestPasswordResetByUsername =  (username) => {
  return  call('/v1/bartholomew/auth/requestReset', {username})
}

export const resetPasswordByResetToken = (token, password, passwordConfirm) => {
  return call('/v1/bartholomew/auth/reset', { token, password, passwordConfirm })
}

export const call = async (suppliedPath, payload) => {
  let path = replace(suppliedPath, /^\/v1\/bartholomew\//, '')
  return _call("POST", '/v1/bartholomew/'+path, payload)
}

export const authenticate = async (username, password) => {
  let resp = await call('/v1/bartholomew/auth/authenticate', {username, password})
  return resp
}

export const checkAuthentication = async () => {
  let resp = await call('/v1/bartholomew/auth/check')
  return resp
}

export const logout = async () => {
  let resp = await call('/v1/bartholomew/auth/logout')
  return resp
}

const defExports = {
  call,
  authenticate,
  checkAuthentication,
  logout,
  tokenStorageKey,
  passwordResetRequest: requestPasswordResetByUsername,
  passwordResetComplete: resetPasswordByResetToken,
}
export default defExports
