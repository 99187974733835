import React from 'react'
import {createRoot} from 'react-dom/client';

import Voodoo from '@legacy/voodoo/Voodoo'
import * as serviceWorker from './serviceWorker'


const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const renderApp = () => {

  root.render(
    <Voodoo />
  )

}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('legacy/voodoo/Voodoo', renderApp)
}

renderApp()

serviceWorker.unregister()
