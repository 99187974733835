import React from 'react'

import Card from '@legacy/hoodoo/components/Card'


const TabContent = (props) => {
  const {
    active,
    card = false,
    ...cardProps
  } = props

  if (!active) return null

  return (
    <Card card={card} {...cardProps} />
  )
}

export default TabContent
